import React from "react";

import { Link, navigate } from "gatsby";
import Grid from "@material-ui/core/Grid";

import md5 from "md5";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { checkAuth } from '../helpers/auth';

const style = {
  container: { display: 'flex', justifyContent: 'space-between', width: '50%', minWidth: '700px', marginBottom: '50px' },
  subApp: {
    width: '300px',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #f4f8f9',
    borderRadius: '10px',
    backgroundColor: '#f4f8f9e0',
    margin: '10px'
  }
};

const subApps = [
  {
    name: 'Media Planner',
    link: '/media-planner',
    role: 'media-planner'
  },
  {
    name: 'Brainy Concepts Licensing Portal',
    link: '/licensing-portal',
    role: 'brainy-concepts-licensing-portal'
  }
]

class Index extends React.Component {
  constructor(props) {
    super(props);
    const roles = checkAuth();
    let norender = true;
    let link;
    if (roles.length == 1) {
      link = subApps.find(a => a.role === roles[0]).link;
      norender = true;
    } else if (roles.includes('media-planner')) {
      link = subApps[0].link;
      norender = true;
    } else {
      norender = false;
    }
    this.state = { roles, norender };

    if(link){
      navigate(link)
    }
  }

  componentDidMount() {
    //this.setState({ roles });
  }

  render() {
    return (!this.state.norender ? 
      <Layout showAvatar={true} showSidebar={false}>
        <SEO title={`Welcome - Choose App`} />
        <h1 style={{ textAlign: "center", padding: 30 }}>Welcome!</h1>

        <Grid container>
          <Grid container justify="center" spacing={1}>
            {this.state.roles && this.state.roles.length === 0 ? 'Sorry but You have no App enabled' :
              <>
                <h3 style={{ textAlign: 'center', width: '100%' }}>Choose the app:</h3>
                <div style={style.container}>
                  {subApps.map(r => (this.state.roles || []).includes(r.role) ?
                    <Link style={style.subApp} key={r.name} to={r.link}>{r.name}</Link>
                    : '')}
                </div>
              </>}

          </Grid>
        </Grid>

      </Layout> : null
    );
  }
}

export default Index;
